import React from "react";
import {
  Login,
  ResetRequest,
  UpdatePassword,
  ErrorPage,
  Info,
  VerifyEmail,
  SamlPostForm,
  LogoutConfirm,
} from "./keycloak-theme";
import { KcContext_Login } from "./keycloak-theme/login/Login";

const mockedKcContext: KcContext_Login = {
  pageId: "login.ftl",
  keycloakifyVersion: "keycloakify-version",
  themeType: "login",
  themeName: "theme-name",
  themeVersion: "theme-version",
  url: {
    loginResetCredentialsUrl: "reset-request",
    registrationUrl: "registration",
    loginAction: "login-action",
    resourcesPath: "resource",
    resourcesCommonPath: "recource-common",
    loginRestartFlowUrl: "restart",
    loginUrl: "login",
  },
  realm: {
    name: "realm-name",
    displayName: "realm-name",
    internationalizationEnabled: true,
    registrationEmailAsUsername: true,
    loginWithEmailAllowed: true,
    rememberMe: false,
    password: true,
    resetPasswordAllowed: true,
    registrationAllowed: true,
  },
  auth: {
    showUsername: true,
    showResetCredentials: true,
    showTryAnotherWayLink: true,
  },
  registrationDisabled: false,
  login: {},
  social: {
    displayInfo: true,
    providers: [
      {
        alias: "saml",
        displayName: "CDW",
        loginUrl: "/realms/cdw-shared/broker/saml/login?client_id=r1s-frontend",
        providerId: "saml",
      },
      {
        alias: "oidc",
        displayName: "Other IPD",
        loginUrl: "/realms/cdw-shared/broker/saml/login?client_id=r1s-frontend",
        providerId: "oidc",
      },
    ],
  },
  scripts: [],
  client: {
    clientId: "string",
    baseUrl: "https://example.com",
  },
  isAppInitiatedAction: true,
  messagesPerField: {
    printIfExists: (fieldName: string) => undefined,
    existsError: (fieldName: string) => false,
    get: (fieldName: string) => "string",
    exists: (fieldName: string) => false,
  },
};

const ROUTES = {
  login: "/login",
  resetRequest: "/reset-request",
  updatePassword: "/update-password",
  error: "/error",
  info: "/info",
  loginExpired: "/login-expired",
  verifyEmail: "/verify-email",
  samlPostForm: "/saml-post-form",
  logoutConfirm: "/logout-confirm",
};

export default function App() {
  switch (window.location.pathname) {
    case ROUTES.login:
      return <Login kcContext={mockedKcContext} />;
    case ROUTES.resetRequest:
      return (
        <ResetRequest
          kcContext={{ ...mockedKcContext, pageId: "login-reset-password.ftl" }}
        />
      );
    case ROUTES.updatePassword:
      return (
        <UpdatePassword
          kcContext={{
            ...mockedKcContext,
            pageId: "login-update-password.ftl",
            username: "user@example.com",
          }}
        />
      );
    case ROUTES.verifyEmail:
      return (
        <VerifyEmail
          kcContext={{
            ...mockedKcContext,
            pageId: "login-verify-email.ftl",
            user: { email: "user@example.com" },
          }}
        />
      );
    case ROUTES.logoutConfirm:
      return (
        <LogoutConfirm
          kcContext={{
            ...mockedKcContext,
            pageId: "logout-confirm.ftl",
            url: {
              ...mockedKcContext.url,
              logoutConfirmAction: "logout-confirm-action",
            },
            logoutConfirm: {
              code: "logout-code",
            },
          }}
        />
      );
    case ROUTES.info:
      return (
        <Info
          kcContext={{
            ...mockedKcContext,
            pageId: "info.ftl",
            message: { type: "info", summary: "There is info text" },
            skipLink: true,
          }}
        />
      );
    case ROUTES.error:
      return (
        <ErrorPage
          kcContext={{
            ...mockedKcContext,
            pageId: "error.ftl",
            message: { type: "error", summary: "There is error text" },
          }}
        />
      );
    case ROUTES.loginExpired:
      return (
        <ErrorPage
          kcContext={{
            ...mockedKcContext,
            pageId: "login-page-expired.ftl",
            message: { type: "error", summary: "Login page expired errror" },
          }}
        />
      );
    case ROUTES.samlPostForm:
      return (
        <SamlPostForm
          kcContext={{
            ...mockedKcContext,
            pageId: "saml-post-form.ftl",
            samlPost: {
              url: "https://example.com",
              SAMLRequest: "SAMLRequest",
              SAMLResponse: "SAMLResponse",
              relayState: "relayState",
            },
          }}
        />
      );
    default:
      return <div>Check App.tsx file to get routes</div>;
  }
}
