import { useEffect, useState } from "react";
import { getQueryParam } from "../utils/getQueryParam";

const ZENDESK_CLIENT_ID = "trg.zendesk.com";
const ZENDESK_RETURNPRO_BRAND_ID = "20897171101453";
const ZENDESK_BRAND_ID_PARAM = "brand_id";
const ZENDESK_RETURN_TO_URL_PARAM = "RelayState";
const ZENDESK_URL_BASE = "https://trg.zendesk.com";

export const useZendeskRedirect = ({
  clientId,
}: {
  clientId: string;
}): { isReturnProLoginRendered: boolean } => {
  const [isReturnProLoginRendered, setIsReturnProLoginRendered] =
    useState<boolean>(false);

  useEffect(() => {
    if (clientId === ZENDESK_CLIENT_ID) {
      const brandId = getQueryParam(
        window.location.href,
        ZENDESK_BRAND_ID_PARAM
      );

      if (brandId === ZENDESK_RETURNPRO_BRAND_ID) {
        setIsReturnProLoginRendered(true);
        return;
      } else if (brandId) {
        const returnToUrl = getQueryParam(
          window.location.href,
          ZENDESK_RETURN_TO_URL_PARAM
        );

        const href = `${ZENDESK_URL_BASE}/auth/v2/login/normal?&brand_id=${brandId}&return_to=${returnToUrl}`;

        window.location.href = href;

        setIsReturnProLoginRendered(false);
      } else {
        setIsReturnProLoginRendered(true);
      }
    } else {
      setIsReturnProLoginRendered(true);
    }
  }, [clientId]);

  return { isReturnProLoginRendered };
};
