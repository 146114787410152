import { memo, useEffect, useRef, useState } from "react";
import cx from "classnames";
import type { KcContext } from "../../kcContext";
import { setCurrentYear } from "../../modules/year";
import "./index.scss";

export type KcContext_Error = Extract<
  KcContext,
  { pageId: "error.ftl" | "login-page-expired.ftl" }
>;

export const ErrorPage = memo(
  ({ kcContext }: { kcContext: KcContext_Error }) => {
    const { message, client } = kcContext;
    const notificationRef = useRef<HTMLDivElement | null>(null);
    const [showNotification, setShowNotification] = useState<boolean>(false);

    useEffect(() => {
      setCurrentYear();
    }, []);

    useEffect(() => {
      if (notificationRef.current && message) {
        setShowNotification(true);
        notificationRef.current.innerHTML = message.summary;
      }
    }, [message]);

    const homePageUrl = client.baseUrl || "";

    const loginUrl = homePageUrl;

    return (
      <div className="container">
        <header className="header"></header>

        <main className="main">
          <div className="form-wrapper" id="form-wrapper">
            <h1>Error has occured</h1>
            <div
              className={cx(
                "notification",
                `notification--${message?.type ?? "error"}`,
                showNotification && "notification--show"
              )}
              id="notification"
            >
              <div className="notification__icon"></div>
              <div className="notification__message">
                <div
                  ref={notificationRef}
                  className="notification__message-title"
                ></div>
              </div>
            </div>
            <a className="link" href={loginUrl}>
              Back to Login
            </a>
          </div>
        </main>

        <footer className="footer">
          <div className="copyright">
            <div className="copyright__text">
              &#169; <span id="year">2023</span> All Rights Reserved.
              <br />
              ReturnPro ® is a registered trademark of The Recon Group, LLP.
            </div>
          </div>
        </footer>
      </div>
    );
  }
);
