import { memo } from "react";
import type { KcContext } from "../../kcContext";
import { EnvelopeSvg } from "../../icons/EnvelopeSvg";
import "./index.scss";
import { FullLogoSvg } from "../../icons/FullLogo";

type User = {
  email: string;
};

export type KcContext_VerifyEmail = Extract<
  KcContext,
  { pageId: "login-verify-email.ftl" }
> & { user?: User };

export const VerifyEmail = memo(
  ({ kcContext }: { kcContext: KcContext_VerifyEmail }) => {
    const { user, client } = kcContext;

    const homePageUrl = client.baseUrl || "";

    const loginUrl = homePageUrl;

    return (
      <div className="container email-container">
        <div className="fulllogo">
          <FullLogoSvg />
        </div>

        <div className="content">
          <div className="heading">
            <EnvelopeSvg />
            <span>Check your inbox</span>
          </div>

          <span className="email-sent">An email has been sent to</span>
          <span className="user-email">{user?.email}</span>

          <span className="please-click">
            Please click the verification link to confirm your email address.
          </span>

          <a className="link" href={loginUrl}>
            Back to Login
          </a>
        </div>
      </div>
    );
  }
);
