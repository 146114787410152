export const ArrowUpSvg = () => (
  <svg
    fill="#16293F"
    aria-hidden="true"
    width="10"
    height="6"
    viewBox="0 0 10 6"
  >
    <path stroke="none" d="M10 6H0L5 0L10 6Z"></path>
  </svg>
);
