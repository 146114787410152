import { memo, useEffect, useRef, useState } from "react";
import { useConstCallback } from "powerhooks/useConstCallback";
import cx from "classnames";
import { KcContext } from "../../kcContext";
import { setCurrentYear } from "../../modules/year";
import "./index.scss";
import { PasswordInputWithHint } from "./PasswordInputWithHint";

type KcContext_UpdatePassword = Extract<
  KcContext,
  { pageId: "login-update-password.ftl" }
>;

export const UpdatePassword = memo(
  ({ kcContext }: { kcContext: KcContext_UpdatePassword }) => {
    const { url, message, username } = kcContext;
    const notificationRef = useRef<HTMLDivElement | null>(null);
    const [showNotification, setShowNotification] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      setCurrentYear();
    }, []);

    useEffect(() => {
      if (notificationRef.current && message) {
        setShowNotification(true);
        notificationRef.current.innerHTML = message.summary;
      }
    }, [message]);

    const onSubmit = useConstCallback(() => {
      setDisabled((prev) => !prev);
      setLoading((prev) => !prev);
    });

    return (
      <div className="container">
        <header className="header"></header>

        <main className="main">
          <div className="form-wrapper" id="form-wrapper">
            <h1>Reset your password</h1>
            <div
              className={cx(
                "notification",
                `notification--${message?.type ?? "warning"}`,
                showNotification && "notification--show"
              )}
              id="notification"
            >
              <div className="notification__icon"></div>
              <div className="notification__message">
                <div
                  ref={notificationRef}
                  className="notification__message-title"
                >
                  as;dja;sd;alsd;sakd
                </div>
              </div>
            </div>

            <form
              className="form"
              id="kc-form-login"
              onSubmit={onSubmit}
              // @ts-ignore TODO: remove when keycloakify will be compatible with keycloak
              action={url.loginAction}
              method="post"
            >
              <div className="form__fields">
                <input hidden id="username" name="username" value={username} />
                <input hidden id="password" name="password" value="" />
                <div className="form__field">
                  <label>
                    Password
                    <PasswordInputWithHint />
                  </label>
                </div>
                <div className="form__field">
                  <label>
                    Repeat password
                    <input
                      id="password-confirm"
                      name="password-confirm"
                      className="input"
                      type="password"
                    />
                  </label>
                </div>
              </div>
              <div className="form__buttons">
                <button
                  className={`button button--main ${
                    loading && "button--loading"
                  }`}
                  id="button-login-submit"
                  type="submit"
                  disabled={disabled}
                >
                  Reset and sign in
                </button>
              </div>
            </form>
          </div>
        </main>

        <footer className="footer">
          <div className="copyright">
            <div className="copyright__text">
              &#169; <span id="year">2023</span> All Rights Reserved.
              <br />
              ReturnPro ® is a registered trademark of The Recon Group, LLP.
            </div>
          </div>
        </footer>
      </div>
    );
  }
);
