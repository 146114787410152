import { useEffect, useRef } from "react";
import type { KcContext } from "../../kcContext";

type KcContext_ConfirmLogout = Extract<
  KcContext,
  { pageId: "logout-confirm.ftl" }
>;

export const LogoutConfirm = ({
  kcContext,
}: {
  kcContext: KcContext_ConfirmLogout;
}) => {
  const { url, logoutConfirm } = kcContext;
  const actionLink = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    actionLink.current?.click();
  }, []);

  return (
    <>
      <div id="kc-logout-confirm" className="content-area">
        <form
          className="form-actions"
          action={url.logoutConfirmAction}
          method="POST"
        >
          <input type="hidden" name="session_code" value={logoutConfirm.code} />
          <div>
            <div id="kc-form-options">
              <div></div>
            </div>
            <div id="kc-form-buttons">
              <input
                tabIndex={4}
                name="confirmLogout"
                id="kc-logout"
                type="submit"
                ref={actionLink}
                style={{ opacity: 0 }}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
