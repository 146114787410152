import React from "react";
import ReactDOM from "react-dom";

// depends to current app - could be removed
import App from "./App";
import "./index.css";

// depends to kc app
import { kcContext } from "./kcContext";
import {
  Login,
  ResetRequest,
  UpdatePassword,
  ErrorPage,
  Info,
  VerifyEmail,
  SamlPostForm,
  LogoutConfirm,
} from "./keycloak-theme";

function isDevEnvironment() {
  return process.env.REACT_APP_ENVIRONMENT === "development";
}

function KcApp() {
  if (kcContext === undefined) {
    throw new Error();
  }

  console.log("keycloak context", kcContext);

  switch (kcContext.pageId) {
    case "login.ftl":
      return <Login kcContext={kcContext} />;
    case "login-reset-password.ftl":
      return <ResetRequest kcContext={kcContext} />;
    case "login-update-password.ftl":
      return <UpdatePassword kcContext={kcContext} />;
    case "login-verify-email.ftl":
      return <VerifyEmail kcContext={kcContext} />;
    case "logout-confirm.ftl":
      return <LogoutConfirm kcContext={kcContext} />;
    case "saml-post-form.ftl":
      return <SamlPostForm kcContext={kcContext} />;
    case "error.ftl":
      return <ErrorPage kcContext={kcContext} />;
    case "info.ftl":
      return <Info kcContext={kcContext} />;
    case "login-page-expired.ftl":
      return (
        <ErrorPage
          kcContext={{ ...kcContext, pageId: "login-page-expired.ftl" }}
        />
      );
  }

  return <>You need to add this page to KcApp at src/index.tsx!</>;
}

ReactDOM.render(
  isDevEnvironment() ? <App /> : <KcApp />,
  document.getElementById("root")
);
