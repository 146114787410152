import { memo, useEffect, useRef, useState } from "react";
import cx from "classnames";
import type { KcContext } from "../../kcContext";
import { setCurrentYear } from "../../modules/year";
import "./index.scss";

export type KcContext_Info = Extract<KcContext, { pageId: "info.ftl" }>;

export const Info = memo(({ kcContext }: { kcContext: KcContext_Info }) => {
  const { message, client, actionUri, pageRedirectUri } = kcContext;
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const actionLink = useRef<HTMLAnchorElement | null>(null);

  const shouldSkipInfoPage =
    message?.summary.includes("Confirm validity of e-mail address") ||
    message?.summary.includes("Perform the following action");

  const shouldProceedOnboardingWizard = message?.summary.includes(
    "Your account has been updated"
  );

  console.log("message", message, message?.summary);
  useEffect(() => {
    if (shouldSkipInfoPage) {
      console.log("shouldSkipInfoPage");
      actionLink.current?.click();
    }
  }, [shouldSkipInfoPage]);

  useEffect(() => {
    if (shouldProceedOnboardingWizard) {
      actionLink.current?.click();
      console.log("shouldProceedOnboardingWizard");
    }
  }, [shouldProceedOnboardingWizard]);

  useEffect(() => {
    setCurrentYear();
  }, []);

  useEffect(() => {
    if (
      !shouldSkipInfoPage &&
      !shouldProceedOnboardingWizard &&
      notificationRef.current &&
      message
    ) {
      setShowNotification(true);
      notificationRef.current.innerHTML = message.summary;
    }
  }, [message, shouldProceedOnboardingWizard, shouldSkipInfoPage]);

  const homePageUrl = client.baseUrl || "";

  if (shouldProceedOnboardingWizard) {
    return (
      <a
        ref={actionLink}
        style={{ opacity: 0 }}
        href={pageRedirectUri || homePageUrl}
      >
        Proceed
      </a>
    );
  }

  if (shouldSkipInfoPage) {
    return (
      <a ref={actionLink} style={{ opacity: 0 }} href={actionUri}>
        Proceed
      </a>
    );
  }

  return (
    <div className="container">
      <header className="header"></header>

      <main className="main">
        <div className="form-wrapper" id="form-wrapper">
          <h1>Info</h1>
          <div
            className={cx(
              "notification",
              `notification--${message?.type ?? "warning"}`,
              showNotification && "notification--show"
            )}
            id="notification"
          >
            <div className="notification__icon"></div>
            <div className="notification__message">
              <div
                ref={notificationRef}
                className="notification__message-title"
              ></div>
            </div>
          </div>
          <a className="link" href={homePageUrl}>
            Go to home page
          </a>
        </div>
      </main>

      <footer className="footer">
        <div className="copyright">
          <div className="copyright__text">
            &#169; <span id="year">2023</span> All Rights Reserved.
            <br />
            ReturnPro ® is a registered trademark of The Recon Group, LLP.
          </div>
        </div>
      </footer>
    </div>
  );
});
