export function getQueryParam(url: string, paramName: string) {
  try {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);
    const value = params.get(paramName);
    return value;
  } catch (error) {
    console.error("Error parsing URL:", error);
    return "";
  }
}
