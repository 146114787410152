export const CheckMarkSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#56C768"
      d="M10 17L19 8.34615L17.6 7L10 14.3077L6.4 10.8462L5 12.1923L10 17Z"
    />
  </svg>
);
