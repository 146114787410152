import { useEffect, useState } from "react";
import type { KcContext } from "../../kcContext";

type KcContext_SamlPostForm = Extract<
  KcContext,
  { pageId: "saml-post-form.ftl" }
>;

export const SamlPostForm = ({
  kcContext,
}: {
  kcContext: KcContext_SamlPostForm;
}) => {
  const { samlPost } = kcContext;

  const [htmlFormElement, setHtmlFormElement] =
    useState<HTMLFormElement | null>(null);

  useEffect(() => {
    if (htmlFormElement === null) {
      return;
    }

    // Storybook
    if (samlPost.url === "") {
      alert("In a real Keycloak the user would be redirected immediately");
      return;
    }

    htmlFormElement.submit();
  }, [htmlFormElement]);
  return (
    <div className="container">
      <header className="header"></header>

      <main className="main">
        <p className="heading">Redirecting...</p>
        <form
          name="saml-post-binding"
          method="post"
          action={samlPost.url}
          ref={setHtmlFormElement}
        >
          {samlPost.SAMLRequest && (
            <input
              type="hidden"
              name="SAMLRequest"
              value={samlPost.SAMLRequest}
            />
          )}
          {samlPost.SAMLResponse && (
            <input
              type="hidden"
              name="SAMLResponse"
              value={samlPost.SAMLResponse}
            />
          )}
          {samlPost.relayState && (
            <input
              type="hidden"
              name="RelayState"
              value={samlPost.relayState}
            />
          )}
          <noscript>
            <input type="submit" value={""} />
          </noscript>
        </form>
      </main>

      <footer className="footer">
        <div className="copyright">
          <div className="copyright__text">
            &#169; <span id="year">2023</span> All Rights Reserved.
            <br />
            ReturnPro ® is a registered trademark of The Recon Group, LLP.
          </div>
        </div>
      </footer>
    </div>
  );
};
