import React, { ChangeEvent, useRef, useState } from "react";
import { CheckMarkSvg } from "../../icons/CheckMarkSvg";
import { CloseSvg } from "../../icons/CloseSvg";
import { ArrowUpSvg } from "../../icons/ArroUpSvg";

const MIN_LENGTH = 8;

export const PasswordInputWithHint = () => {
  const [passwordInputFocus, setPasswordInputFocus] = useState(false);
  const [password, setPassword] = useState("");
  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasDigit = /[0-9]/.test(password);
  const isMinimumLength = !!password && password.length >= MIN_LENGTH;

  const renderIcon = (condition: boolean) => (
    <div className="password__check-icon-wrapper">
      {condition ? <CheckMarkSvg /> : <CloseSvg />}
    </div>
  );

  const checkConditions = [
    { condition: isMinimumLength, text: "8 or more characters" },
    { condition: hasLowerCase, text: "lowercase letters" },
    { condition: hasUpperCase, text: "uppercase letters" },
    { condition: hasDigit, text: "at least 1 digit" },
  ];

  const handlePasswordInputFocus = () => {
    setPasswordInputFocus(true);
  };

  const handlePasswordInputBlur = () => {
    setPasswordInputFocus(false);
  };

  const handlePasswordInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div className="password__wrapper">
      {passwordInputFocus ? (
        <div
          className="password__check-container"
          style={{
            width: passwordInputRef.current?.offsetWidth,
            top: passwordInputRef.current?.offsetHeight,
          }}
        >
          <ArrowUpSvg />
          <div className="password__check-wrapper">
            <span>Password must have:</span>
            {checkConditions.map(({ condition, text }) => (
              <div key={text} className="password__check-condition-wrapper">
                {renderIcon(condition)}
                <span color={condition ? "#56C768" : "white"}>{text}</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <input
        ref={passwordInputRef}
        id="password-new"
        name="password-new"
        className="input"
        type="password"
        autoFocus
        onChange={handlePasswordInputChange}
        onFocus={handlePasswordInputFocus}
        onBlur={handlePasswordInputBlur}
      />

      <div className="form__subtitle">
        Password must have 8 or more characters, uppercase and lowercase
        letters, and at least 1 digit.
      </div>
    </div>
  );
};
