import React, { memo, useState } from "react";
import { ReturnProSvgLarge } from "../../icons/ReturnProSvgLarge";
import type { KcContext } from "../../kcContext";
import "./index.scss";

type KcContext_ResetRequest = Extract<
  KcContext,
  { pageId: "login-reset-password.ftl" }
>;

export const ResetRequest = memo(
  ({ kcContext }: { kcContext: KcContext_ResetRequest }) => {
    const [login, setLogin] = useState<string>("");
    const { url } = kcContext;

    return (
      <div className="container">
        <main className="main">
          <div className="form-wrapper" id="reset-request-form-wrapper">
            <div className="logo-large">
              <ReturnProSvgLarge />
            </div>
            <h1 className="form__title">
              Retrieve your ReturnPro username and reset password
            </h1>
            <div className="notification notification--error" id="notification">
              <div className="notification__icon"></div>
              <div className="notification__message">
                <div
                  className="notification__message-title"
                  id="notification-title"
                ></div>
                <p
                  className="notification__message-body"
                  id="notification-body"
                ></p>
              </div>
            </div>

            <form
              className="form"
              id="reset-request-form"
              action={url.loginAction}
              method="post"
            >
              <div className="form__fields">
                <div className="form__field">
                  <label>
                    Email address
                    <input
                      id="username"
                      className="input"
                      placeholder="email@gotrg.com"
                      type="email"
                      name="username"
                      autoComplete="email"
                      autoFocus
                      value={login}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLogin(event.target.value)
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="form__buttons">
                <a
                  href={url.loginUrl}
                  className="button button--secondary button--secondary-transparent"
                >
                  Cancel
                </a>
                <button
                  className="button button--main"
                  id="button-reset-request-submit"
                  disabled={!login}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    );
  }
);
