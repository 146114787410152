import { getKcContext } from "keycloakify/login";

export const { kcContext } = getKcContext({
  mockData: [
    {
      pageId: "login.ftl",
    },
    {
      pageId: "error.ftl",
    },
    {
      pageId: "info.ftl",
    },
    {
      pageId: "login-reset-password.ftl",
    },
    {
      pageId: "login-verify-email.ftl",
    },
    {
      pageId: "login-update-password.ftl",
    },
    {
      pageId: "login-page-expired.ftl",
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext> & {
  client: {
    clientId: string;
    name?: string | undefined;
    description?: string | undefined;
    baseUrl?: string | undefined;
  };
};
